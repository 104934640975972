import PropTypes from 'prop-types';
import React from 'react';
import { connect } from '../../../common/components/runtime-context';
import { REDUCERS } from '../../../common/components/runtime-context/reducers';
import Link from '../../../app/components/link/internal-link';
import { getCategory } from '../../../common/selectors/categories-selectors';

const PostLink = ({ category, className, post, children }) => (
  <Link className={className} to={`/${category.slug}/${post.slug}`}>
    {children}
  </Link>
);

PostLink.propTypes = {
  post: PropTypes.object.isRequired,
  className: PropTypes.string,
};

const mapRuntimeToProps = (state, { post }) => ({
  category: getCategory(state, post.categoryId),
});

export default connect(mapRuntimeToProps, [REDUCERS.CATEGORIES])(PostLink);
