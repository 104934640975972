import { getComponentValue } from '../../common/store/component-data/component-data-selectors';
import {
  WIDGET_CATEGORY_ID,
  WIDGET_POSTS_ORDER_BY,
} from '@wix/communities-forum-client-commons/dist/src/constants/wix-params-rpw';

export const getWidgetCategoryId = state =>
  getComponentValue({ state, key: WIDGET_CATEGORY_ID, fallback: null });

export const getWidgetPostsOrderBy = state =>
  getComponentValue({ state, key: WIDGET_POSTS_ORDER_BY, fallback: 'createdDate' });
